<template>
  <div class="flightbooking_form_middelitem">
    <h4>{{$t("sabre.booking.seat-box.adding-for")}} {{$t(`sabre.composition.${data.passengerType}`)}} {{ passengerIndex + 1 }} - {{personName}}</h4>

    <seat-one-way-item v-for="(segment, index) in departureSegments" :key="index"
      :dest="segment.arrival.city"
      :segmentIndex="index"
      :passengerIndex="passengerIndex"
      @setSegmentData="() => showChooseSeatModal({segmentIndex: index, passengerIndex, flightIndex: 0})"
      :flightIndex="0"/>

    <seat-one-way-item v-for="(segment, index) in returnSegments" :key="index + departureSegments.length"
      :dest="segment.arrival.city"
      :segmentIndex="index"
      :passengerIndex="passengerIndex"
      @setSegmentData="() => showChooseSeatModal({segmentIndex: index, passengerIndex, flightIndex: 1})"
      :flightIndex="1"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'seat-one-form',
  components: {
    SeatOneWayItem: () => import('./seatOneWayItem'),
  },
  props: {
    passengerIndex: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      condition: 'GET_SABRE_ORDERED_CONDITION',
      directionList: 'GET_SABRE_FLIGHT_DIRECTION_LIST',
      orderedFlight: 'GET_SABRE_ORDERED_FLIGHT',
    }),
    isOneWay() {
      const { condition, directionList } = this;
      return condition.flightDirection === directionList[1];
    },
    personName() {
      const { data } = this;
      return `${data.firstName} ${data.lastName}`;
    },
    departureSegments() {
      const { orderedFlight } = this;
      return orderedFlight.legs[0].segmentExcerpts;
    },
    returnSegments() {
      const { orderedFlight, isOneWay } = this;
      if (isOneWay) return [];
      return orderedFlight.legs[1].segmentExcerpts;
    },
  },
  methods: {
    showChooseSeatModal(payload) {
      this.$emit('sendSegmentData', payload);
    },
  },
};
</script>
